@font-face {
    font-family: 'CorisandeLight';
    src: url('/assets/fonts/CorisandeLight.ttf') format('truetype');
}
@font-face {
    font-family: 'CorisandeBold';
    src: url('/assets/fonts/CorisandeBold.ttf') format('truetype');
}

$light: 'CorisandeLight';
$bold: 'CorisandeBold';

$blue: #005ca9;
$gray: #707070;
$white: #ffffff;
$red: red;
$green: rgb(13, 177, 13);

$default-padding: 40px 20px;

button.fedrigoni {
    background: $blue;
    border: none;
    border-radius: 8px;
    color: white;
    padding: 10px 25px;
    font-family: $bold;
    font-size: 14px;
}

p.fedrigoni-link {
    font-family: $bold;
    border-bottom: 1.5px solid $blue;
    color: $blue;
}

div.checkbox {
    flex: 0 0 22px;
    width: 22px;
    height: 22px;
    box-sizing: border-box;
    border: 2px solid $white;
    border-radius: 3px;
    margin-top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px 0 0;

    i {
        font-size: 15px;
    }
}

div.checkbox.selection_disabled {
    opacity: 0.2;
}