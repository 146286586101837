@mixin for-phone-only {
    @media (max-width: 599px) { @content; }
}
@mixin for-tablet-portrait-up {
    @media (min-width: 600px) { @content; }
}
@mixin for-tablet-landscape-up {
    @media (min-width: 900px) { @content; }
}
@mixin for-desktop-up {
    @media (min-width: 1200px) { @content; }
}
@mixin for-big-desktop-up {
    @media (min-width: 1800px) { @content; }
}

@mixin for-iphone-x-only {
    /* iPhone X / XS - Max / non-Max */
    @media only screen
    and (min-device-width : 375px) 
    and (max-device-width : 812px) 
    and (-webkit-device-pixel-ratio : 3)
    and (orientation : portrait) { @content; }

    /* iPhone XR */
    @media only screen 
    and (min-device-width: 414px) 
    and (min-device-height: 896px) 
    and (-webkit-device-pixel-ratio: 2) 
    and (orientation: portrait) { @content; }
}